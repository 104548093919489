export const nbaLogoMap = [
    {
        "city": "Boston",
        "mascot": "Celtics",
        "short": "BOS",
        "homeText": "FFFFFF",
        "homeMain": "007A33",
        "homeSecondary": "BA9653",
        "awayText": "007A33",
        "awayMain": "FFFFFF",
        "awaySecondary": "BA9653",
        "logoURL": "https://dl.dropbox.com/s/stwlfpz6133sfyu/celtics.png"
    },
    {
        "city": "Brooklyn",
        "mascot": "Nets",
        "short": "BKN",
        "homeText": "FFFFFF",
        "homeMain": "000000",
        "homeSecondary": "202020",
        "awayText": "000000",
        "awayMain": "FFFFFF",
        "awaySecondary": "202020",
        "logoURL": "https://dl.dropbox.com/s/p5tuqs8lrrhvvc3/nets.png"
    },
    {
        "city": "New York",
        "mascot": "Knicks",
        "short": "NYK",
        "homeText": "FFFFFF",
        "homeMain": "006BB6",
        "homeSecondary": "F58426",
        "awayText": "006BB6",
        "awayMain": "FFFFFF",
        "awaySecondary": "F58426",
        "logoURL": "https://dl.dropbox.com/s/xuo57vd0gyhrlj8/knicks.png"
    },
    {
        "city": "Philadelphia",
        "mascot": "76ers",
        "short": "PHI",
        "homeText": "FFFFFF",
        "homeMain": "006BB6",
        "homeSecondary": "ED174C",
        "awayText": "006BB6",
        "awayMain": "FFFFFF",
        "awaySecondary": "ED174C",
        "logoURL": "https://dl.dropbox.com/s/o7y1ux0fjiskz0o/76ers.png"
    },
    {
        "city": "Toronto",
        "mascot": "Raptors",
        "short": "TOR",
        "homeText": "FFFFFF",
        "homeMain": "CE1141",
        "homeSecondary": "000000",
        "awayText": "CE1141",
        "awayMain": "FFFFFF",
        "awaySecondary": "000000",
        "logoURL": "https://dl.dropbox.com/s/kjc3yw2ruav0tf4/raptors.png"
    },
    {
        "city": "Chicago",
        "mascot": "Bulls",
        "short": "CHI",
        "homeText": "FFFFFF",
        "homeMain": "CE1141",
        "homeSecondary": "000000",
        "awayText": "CE1141",
        "awayMain": "FFFFFF",
        "awaySecondary": "000000",
        "logoURL": "https://dl.dropbox.com/s/zv2ppdd103v740h/bulls.png"
    },
    {
        "city": "Clevland",
        "mascot": "Cavaliers",
        "short": "CLE",
        "homeText": "FFFFFF",
        "homeMain": "860038",
        "homeSecondary": "041E42",
        "awayText": "860038",
        "awayMain": "FFFFFF",
        "awaySecondary": "041E42",
        "logoURL": "https://dl.dropbox.com/s/j8hx8fb6kaoqu6e/cavaliers.png"
    },
    {
        "city": "Detroit",
        "mascot": "Pistons",
        "short": "DET",
        "homeText": "FFFFFF",
        "homeMain": "C8102E",
        "homeSecondary": "1D42BA",
        "awayText": "C8102E",
        "awayMain": "FFFFFF",
        "awaySecondary": "1D42BA",
        "logoURL": "https://dl.dropbox.com/s/nqr42w8zry3ek28/pistons.png"
    },
    {
        "city": "Indiana",
        "mascot": "Pacers",
        "short": "IND",
        "homeText": "FFFFFF",
        "homeMain": "002D62",
        "homeSecondary": "FDBB30",
        "awayText": "002D62",
        "awayMain": "FFFFFF",
        "awaySecondary": "FDBB30",
        "logoURL": "https://dl.dropbox.com/s/lskmc4k48o69jtc/pacers.png"
    },
    {
        "city": "Milwaukee",
        "mascot": "Bucks",
        "short": "MIL",
        "homeText": "FFFFFF",
        "homeMain": "00471B",
        "homeSecondary": "EEE1C6",
        "awayText": "00471B",
        "awayMain": "FFFFFF",
        "awaySecondary": "EEE1C6",
        "logoURL": "https://dl.dropbox.com/s/xghc9v5bvlu9d1s/bucks.png"
    },
    {
        "city": "Atlanta",
        "mascot": "Hawks",
        "short": "ATL",
        "homeText": "FFFFFF",
        "homeMain": "E03A3E",
        "homeSecondary": "C1D32F",
        "awayText": "E03A3E",
        "awayMain": "FFFFFF",
        "awaySecondary": "C1D32F",
        "logoURL": "https://dl.dropbox.com/s/yt85ehn6yejf0kf/hawks.png"
    },
    {
        "city": "Charlotte",
        "mascot": "Hornets",
        "short": "CHA",
        "homeText": "FFFFFF",
        "homeMain": "1D1160",
        "homeSecondary": "00788C",
        "awayText": "1D1160",
        "awayMain": "FFFFFF",
        "awaySecondary": "00788C",
        "logoURL": "https://dl.dropbox.com/s/1mftukz5dye49pi/hornets.png"
    },
    {
        "city": "Miami",
        "mascot": "Heat",
        "short": "MIA",
        "homeText": "FFFFFF",
        "homeMain": "98002E",
        "homeSecondary": "F9A01B",
        "awayText": "98002E",
        "awayMain": "FFFFFF",
        "awaySecondary": "F9A01B",
        "logoURL": "https://dl.dropbox.com/s/txvlmcagf7vzsqh/heat.png"
    },
    {
        "city": "Orlando",
        "mascot": "Magic",
        "short": "ORL",
        "homeText": "FFFFFF",
        "homeMain": "0077C0",
        "homeSecondary": "C4CED4",
        "awayText": "0077C0",
        "awayMain": "FFFFFF",
        "awaySecondary": "C4CED4",
        "logoURL": "https://dl.dropbox.com/s/q1bs26gv3w0dvr4/magic.png"
    },
    {
        "city": "Washington",
        "mascot": "Wizards",
        "short": "WAS",
        "homeText": "FFFFFF",
        "homeMain": "002B5C",
        "homeSecondary": "E31837",
        "awayText": "002B5C",
        "awayMain": "FFFFFF",
        "awaySecondary": "E31837",
        "logoURL": "https://dl.dropbox.com/s/s4iomls5tyrnsuy/wizards.png"
    },
    {
        "city": "Denver",
        "mascot": "Nuggets",
        "short": "DEN",
        "homeText": "FFFFFF",
        "homeMain": "0E2240",
        "homeSecondary": "FEC524",
        "awayText": "0E2240",
        "awayMain": "FFFFFF",
        "awaySecondary": "FEC524",
        "logoURL": "https://dl.dropbox.com/s/ahcgtvi3zihq9b5/nuggets.png"
    },
    {
        "city": "Minnesota",
        "mascot": "Timberwolves",
        "short": "MIN",
        "homeText": "FFFFFF",
        "homeMain": "0C2340",
        "homeSecondary": "236192",
        "awayText": "0C2340",
        "awayMain": "FFFFFF",
        "awaySecondary": "236192",
        "logoURL": "https://dl.dropbox.com/s/rwfrtkb5uqtlnnl/timberwolves.png"
    },
    {
        "city": "Okalahoma City",
        "mascot": "Thunder",
        "short": "OKC",
        "homeText": "FFFFFF",
        "homeMain": "007AC1",
        "homeSecondary": "EF3B24",
        "awayText": "007AC1",
        "awayMain": "FFFFFF",
        "awaySecondary": "EF3B24",
        "logoURL": "https://dl.dropbox.com/s/g2x91ge1t7gjrpn/thunder.png"
    },
    {
        "city": "Portland",
        "mascot": "Trail Blazers",
        "short": "POR",
        "homeText": "FFFFFF",
        "homeMain": "E03A3E",
        "homeSecondary": "000000",
        "awayText": "E03A3E",
        "awayMain": "FFFFFF",
        "awaySecondary": "000000",
        "logoURL": "https://dl.dropbox.com/s/rrnf9bp4y9pqj74/trailblazers.png"
    },
    {
        "city": "Utah",
        "mascot": "Jazz",
        "short": "UTA",
        "homeText": "FFFFFF",
        "homeMain": "002B5C",
        "homeSecondary": "00471B",
        "awayText": "002B5C",
        "awayMain": "FFFFFF",
        "awaySecondary": "00471B",
        "logoURL": "https://dl.dropbox.com/s/st62bfjr7awa3mb/jazz.png"
    },
    {
        "city": "Golden State",
        "mascot": "Warriors",
        "short": "GSW",
        "homeText": "FFFFFF",
        "homeMain": "1D42BA",
        "homeSecondary": "FFC72C",
        "awayText": "1D42BA",
        "awayMain": "FFFFFF",
        "awaySecondary": "FFC72C",
        "logoURL": "https://dl.dropbox.com/s/9r6bwy31obrpst1/warriors.png"
    },
    {
        "city": "Los Angeles",
        "mascot": "Clippers",
        "short": "LAC",
        "homeText": "FFFFFF",
        "homeMain": "C8102E",
        "homeSecondary": "1D428A",
        "awayText": "C8102E",
        "awayMain": "FFFFFF",
        "awaySecondary": "1D428A",
        "logoURL": "https://dl.dropbox.com/s/lw7bnzunzvo4l47/clippers.png"
    },
    {
        "city": "Los Angeles",
        "mascot": "Lakers",
        "short": "LAL",
        "homeText": "FFFFFF",
        "homeMain": "552583",
        "homeSecondary": "FDB927",
        "awayText": "552583",
        "awayMain": "FFFFFF",
        "awaySecondary": "FDB927",
        "logoURL": "https://dl.dropbox.com/s/g7ug46b3ylejosu/lakers.png"
    },
    {
        "city": "Phoenix",
        "mascot": "Suns",
        "short": "PHX",
        "homeText": "FFFFFF",
        "homeMain": "1D1160",
        "homeSecondary": "E56020",
        "awayText": "1D1160",
        "awayMain": "FFFFFF",
        "awaySecondary": "E56020",
        "logoURL": "https://dl.dropbox.com/s/pvos4hyia4fd5rw/suns.png"
    },
    {
        "city": "Sacramento",
        "mascot": "Kings",
        "short": "SAC",
        "homeText": "FFFFFF",
        "homeMain": "5A2D81",
        "homeSecondary": "63727A",
        "awayText": "5A2D81",
        "awayMain": "FFFFFF",
        "awaySecondary": "63727A",
        "logoURL": "https://dl.dropbox.com/s/f1uamckcbm9fn0z/kings.png"
    },
    {
        "city": "Dallas",
        "mascot": "Mavericks",
        "short": "DAL",
        "homeText": "FFFFFF",
        "homeMain": "00538C",
        "homeSecondary": "002B5E",
        "awayText": "00538C",
        "awayMain": "FFFFFF",
        "awaySecondary": "002B5E",
        "logoURL": "https://dl.dropbox.com/s/1r5857l4yonbh8r/mavericks.png"
    },
    {
        "city": "Houston",
        "mascot": "Rockets",
        "short": "HOU",
        "homeText": "FFFFFF",
        "homeMain": "CE1141",
        "homeSecondary": "000000",
        "awayText": "CE1141",
        "awayMain": "FFFFFF",
        "awaySecondary": "000000",
        "logoURL": "https://dl.dropbox.com/s/252gktsejwllxlb/rockets.png"
    },
    {
        "city": "Memphis",
        "mascot": "Grizzlies",
        "short": "MEM",
        "homeText": "FFFFFF",
        "homeMain": "5D76A9",
        "homeSecondary": "12173F",
        "awayText": "5D76A9",
        "awayMain": "FFFFFF",
        "awaySecondary": "12173F",
        "logoURL": "https://dl.dropbox.com/s/l70m376qlhaktj6/grizzlies.png"
    },
    {
        "city": "New Orleans",
        "mascot": "Pelicans",
        "short": "NOP",
        "homeText": "FFFFFF",
        "homeMain": "0C2340",
        "homeSecondary": "C8102E",
        "awayText": "0C2340",
        "awayMain": "FFFFFF",
        "awaySecondary": "C8102E",
        "logoURL": "https://dl.dropbox.com/s/8eqhc1eo3cj9c78/pelicans.png"
    },
    {
        "city": "San Antonio",
        "mascot": "Spurs",
        "short": "SAS",
        "homeText": "FFFFFF",
        "homeMain": "C4CED4",
        "homeSecondary": "000000",
        "awayText": "C4CED4",
        "awayMain": "FFFFFF",
        "awaySecondary": "000000",
        "logoURL": "https://dl.dropbox.com/s/49kjddkrblgrtvc/spurs.png"
    }
]